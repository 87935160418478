import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ERemittaBiller } from '../models/remita.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends BaseService {
  constructor() {
    super();
    this.baseURL = `v1/payment/`;
  }
  checkRRRIsPaid = (query: { rrr: string; billerType: ERemittaBiller }) =>
    this.get<{ code: string; message: string }>(`remita/status`, query);
}
