import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ICompany, ISaveCompany } from './company.model';
import { ICodeDescription, ICodeTitle } from 'ets-fe-ng-sdk';
export namespace CompanyStore {
  //#region STORE
  export interface IState extends EntityState<ICompany> {
    fetchedAll?: boolean;
    lite?: ICodeDescription[]
  }

  const adapter: EntityAdapter<ICompany> = createEntityAdapter<ICompany>({
    sortComparer: (a, b) => a.companyCode.localeCompare(b.companyCode),
    selectId: (i) => i.companyCode,
  });

  const initialState: IState = adapter.getInitialState();
  //#endregion

  //#region ACTIONS
  export const actions = {
    API: createActionGroup({
      source: 'Company API',
      events: {
        create: props<{ item: ISaveCompany }>(),
        createError: props<{ error: any }>(),
        createSuccess: props<{ result: ICompany }>(),
        fetchAll: emptyProps(),
        fetchAllReset: emptyProps(),
        fetchAllError: props<{ error: any }>(),
        fetchAllSuccess: props<{ result: ICompany[] }>(),
        fetchAllLite: emptyProps(),
        fetchAllLiteSuccess: props<{ result: ICodeDescription[] }>(),
        fetchSingle: props<{ code: string }>(),
        fetchSingleError: props<{ error: any }>(),
        fetchSingleSuccess: props<{ result: ICompany }>(),
        update: props<{ item: ISaveCompany }>(),
        updateError: props<{ error: any }>(),
        updateSuccess: props<{ result: ICompany }>(),
      },
    }),
  };
  //#endregion

  //#region SELECTORE
  export namespace selectors {
    const select = (state: IAppState) => state.company;

    export const selectAll = createSelector(select, adapter.getSelectors().selectAll);
    export const selectAllLite = createSelector(select, s => s.lite);

    export const selectAllForInput = createSelector(
      selectAllLite,
      (list) => list?.map<ICodeTitle>((x) => ({ code: x.code, title: x.description })) || [],
    );
    export const selectAllMap = createSelector(select, adapter.getSelectors().selectEntities);

    export const selectByCode = (code: string) => createSelector(selectAllMap, (map) => map[code]);
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(actions.API.fetchAllSuccess, (state, { result: items }) => adapter.setAll(items, state)),
    on(actions.API.fetchAllSuccess, (state, { result: items }) => ({ ...state, fetchedAll: true })),
    on(actions.API.fetchAllLiteSuccess, (state, { result }) => ({ ...state, lite: result })),
    on(actions.API.fetchSingleSuccess, (state, { result: item }) =>
      state.fetchedAll ? adapter.setOne(item, state) : state,
    ),
    on(actions.API.createSuccess, (state, { result: item }) =>
      state.fetchedAll ? adapter.setOne(item, state) : state,
    ),
    on(actions.API.updateSuccess, (state, { result: item }) =>
      state.fetchedAll ? adapter.setOne(item, state) : state,
    ),
  );
  //#endregion
}
