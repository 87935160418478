import { Injectable, inject } from '@angular/core';
import { catchError, exhaustMap, filter, first, map, mergeMap, of, tap } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';
import { CompaniesService } from '../../../../../admin/src/app/dashboard/set-ups/companies/companies.service';
import { CompanyStore } from './company.reducer';
import { BaseEffect } from '../base.effect';
import { AuthStore } from '../auth/auth.reducer';

@Injectable()
export class CompanyEffect extends BaseEffect {
  protected service = inject(CompaniesService);

  fetchAll = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyStore.actions.API.fetchAll),
      mergeMap((action) =>
        this.store.select(CompanyStore.selectors.selectAll).pipe(
          first(),
          map((r) => ({ existing: r, action })),
        ),
      ),
      tap(() => console.log('selecting company')),
      filter((action) => !action.existing.length),
      exhaustMap((action) =>
        this.service.fetchAll().pipe(
          map((r) => CompanyStore.actions.API.fetchAllSuccess({ result: r.content })),
          catchError((error) => of(CompanyStore.actions.API.fetchAllError({ error }))),
        ),
      ),
    ),
  );

  fetchAllLite = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyStore.actions.API.fetchAllLite),
      mergeMap((action) =>
        this.store.select(CompanyStore.selectors.selectAllLite).pipe(
          first(),
          map((r) => ({ existing: r, action })),
        ),
      ),
      filter((action) => !action.existing?.length),
      exhaustMap((action) =>
        this.service.fetchAllLite().pipe(
          map((r) => CompanyStore.actions.API.fetchAllLiteSuccess({ result: r })),
        ),
      ),
    ),
  );

  fetchAllReset = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyStore.actions.API.fetchAllReset),
      exhaustMap((action) =>
        this.service.fetchAll().pipe(
          map((r) => CompanyStore.actions.API.fetchAllSuccess({ result: r.content })),
          catchError((error) => of(CompanyStore.actions.API.fetchAllError({ error }))),
        ),
      ),
    ),
  );

  fetchSingle = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyStore.actions.API.fetchSingle),
      exhaustMap((action) =>
        this.service.fetchAll({ companyCode: action.code }).pipe(
          map((r) => CompanyStore.actions.API.fetchSingleSuccess({ result: r.content?.[0] })),
          catchError((error) => of(CompanyStore.actions.API.fetchSingleError({ error }))),
        ),
      ),
    ),
  );

  createSuccess = this.actions$.pipe(
    ofType(CompanyStore.actions.API.createSuccess),
    tap(() => this.store.dispatch(CompanyStore.actions.API.fetchAllReset())),
    tap(() => this.store.dispatch(AuthStore.actions.getUserCompany())),
  );
  createError = this.actions$.pipe(ofType(CompanyStore.actions.API.createError));
  updateSuccess = this.actions$.pipe(
    ofType(CompanyStore.actions.API.updateSuccess),
    tap(() => this.store.dispatch(CompanyStore.actions.API.fetchAllReset())),
    tap(() => this.store.dispatch(AuthStore.actions.getUserCompany())),
  );
  updateError = this.actions$.pipe(ofType(CompanyStore.actions.API.updateError));

  create = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyStore.actions.API.create),
      exhaustMap((action) =>
        this.service.create(action.item).pipe(
          map((r) =>
            CompanyStore.actions.API.createSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(CompanyStore.actions.API.createError({ error }))),
        ),
      ),
    ),
  );

  update = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyStore.actions.API.update),
      exhaustMap((action) =>
        this.service.update(action.item).pipe(
          map((r) =>
            CompanyStore.actions.API.updateSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(CompanyStore.actions.API.updateError({ error }))),
        ),
      ),
    ),
  );

  // loginFailure = this.actions$.pipe(ofType(AuthActions.AuthAPI.loginFailure), this.uS.ngrxError);

  // logout = this.actions$
  //   .pipe(
  //     ofType(AuthActions.logout),
  //     tap((action) => {
  //       this.service.logout();
  //       this.router.navigateByUrl('/');
  //     }),
  //   )
  //   .subscribe();

  // requestPasswordReset = createEffect(() => {
  //   const loader = this.uS.loader2();
  //   return this.actions$.pipe(
  //     ofType(AuthActions.requestPasswordReset),
  //     tap(async () => {
  //       (await loader).start();
  //     }),
  //     exhaustMap((action) =>
  //       this.service
  //         .forgetPassword({
  //           username: action.username,
  //           loginUrl: location.origin + '/login',
  //           passwordAuthUrl: location.origin + '/update-password',
  //         })
  //         .pipe(
  //           map((user) => AuthActions.AuthAPI.requestPasswordResetSuccess()),
  //           catchError((error) => of(AuthActions.AuthAPI.requestPasswordResetFailure({ error }))),
  //           tap(async () => {
  //             (await loader).close();
  //           }),
  //         ),
  //     ),
  //   );
  // });

  // requestPasswordResetSuccess = this.actions$.pipe(
  //   ofType(AuthActions.AuthAPI.requestPasswordResetSuccess),
  //   tap((action) => {
  //     this.uS.info('The instructions to reset your password have been sent to your email address.');
  //   }),
  // );

  // requestPasswordResetFailure = this.actions$.pipe(
  //   ofType(AuthActions.AuthAPI.requestPasswordResetFailure),
  //   this.uS.ngrxError,
  // );

  // validateReference = createEffect(() => {
  //   const loader = this.uS.loader2();
  //   return this.actions$.pipe(
  //     ofType(AuthActions.ResetPasswordPage.validateReference),
  //     tap(async () => {
  //       (await loader).start();
  //     }),
  //     exhaustMap((action) =>
  //       this.service.validateReference(action.reference).pipe(
  //         map((r) =>
  //           AuthActions.ResetPasswordAPI.validateReferenceSuccess({
  //             reference: r.reference,
  //             username: r.username || r.email,
  //           }),
  //         ),
  //         catchError((error) => of(AuthActions.ResetPasswordAPI.validateReferenceFailure({ error }))),
  //         tap(async () => {
  //           (await loader).close();
  //         }),
  //       ),
  //     ),
  //   );
  // });

  // validateReferenceSuccess = this.actions$.pipe(
  //   ofType(AuthActions.ResetPasswordAPI.validateReferenceSuccess),
  //   tap((action) => {}),
  // );

  // validateReferenceFailure = this.actions$.pipe(
  //   ofType(AuthActions.ResetPasswordAPI.resetPasswordFailure),
  //   this.uS.ngrxError,
  // );

  // resetPassword = createEffect(() => {
  //   const loader = this.uS.loader2();
  //   return this.actions$.pipe(
  //     ofType(AuthActions.ResetPasswordPage.resetPassword),
  //     tap(async () => {
  //       (await loader).start();
  //     }),
  //     exhaustMap((action) =>
  //       this.service
  //         .updatePasswordWithReference({
  //           confirmPassword: action.password,
  //           emailOrUsername: action.emailOrUsername,
  //           password: action.password,
  //           reference: action.reference,
  //         })
  //         .pipe(
  //           map((r) =>
  //             AuthActions.ResetPasswordAPI.resetPasswordSuccess({
  //               password: action.password,
  //               username: action.emailOrUsername,
  //             }),
  //           ),
  //           catchError((error) => of(AuthActions.ResetPasswordAPI.resetPasswordFailure({ error }))),
  //           tap(async () => {
  //             (await loader).close();
  //           }),
  //         ),
  //     ),
  //   );
  // });

  // resetPasswordSuccess = this.actions$.pipe(
  //   ofType(AuthActions.ResetPasswordAPI.resetPasswordSuccess),
  //   exhaustMap((action) =>
  //     of(
  //       AuthActions.login({
  //         credentials: { password: action.password, userName: action.username },
  //       }),
  //     ),
  //   ),
  // );

  // resetPasswordFailure = this.actions$.pipe(
  //   ofType(AuthActions.ResetPasswordAPI.resetPasswordFailure),
  //   this.uS.ngrxError,
  // );
}
