import { computed, Injectable } from '@angular/core';
import { ISR } from '../../../../../../shared/src/lib/models/index.model';
import {
  ICompany,
  ICompanyAccount,
  ISaveCompany,
} from '../../../../../../shared/src/lib/@ngrx/company/company.model';
import { BaseService } from '../../../../../../shared/src/lib/services/base.service';
import { ICodeDescription, IGetQuery, ISearchResponse2, TableCol } from 'ets-fe-ng-sdk';
import { UtilityService } from '../../../../../../shared/src/lib/services/utility.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService extends BaseService {
  readonly accountsColumns = computed<TableCol<ICompanyAccount>[]>(() => [
    { f: 'ria', t: 'RIA' },
    { f: 'createdBy', t: 'Created By' },
    { f: 'externalRef', t: 'External Ref.' },
    { f: 'grossAmount', t: 'Gross Amount', formatter: this.uS.moneyParser },
    { f: 'paid', t: 'Paid', formatter: this.uS.yesNoCellFormatterColoured },
    { f: 'rrr', t: 'RRR' },
    { f: 'createdOn', t: 'Created On', formatter: this.uS.fullDateTime },
  ]);
  constructor(public uS: UtilityService) {
    super();
    this.baseURL = `v1/company/`;
  }
  readonly checkRRRIsPaid = (rrr: string) =>
    this.get<{ code: string; message: string }>(`payment/status/${rrr}`);
  readonly searchAccounts = (
    query: IGetQuery<{
      company: string;
      createdFrom: string;
      createdTo: string;
      generated: boolean;
      paid: boolean;
      reason: string;
      rrr: string;
    }>,
  ) => this.get<ISearchResponse2<ICompanyAccount>>(`account/details/search`, query);

  fetchAll(query?: { companyCode?: string }) {
    return this.get<ISR<ICompany>>(`search`, query);
  }
  fetchAllLite() {
    return this.get<ICodeDescription[]>(`code/description`).pipe(map(r => r?.sort3('code')));
  }

  create(data: ISaveCompany) {
    return this.post<ICompany>(data);
  }

  update(data: ISaveCompany) {
    return this.put<ICompany>(data);
  }
}
